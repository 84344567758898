/* @LAYOUT */

.auth-panel {
  background: var(--ac-white);
  box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.5);
}

.auth-info {
  background-image: url(../img/bg-auth-panel-blue.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}

.auth-info--logo-wrapper {
  padding: 20px 30px 15px 0;
  text-align: center;
}

.auth-info--logo {
  width: 50px;
  margin: 0 auto;
}

.service-list,
.auth-info--logos {
  display: none;
}

.auth-form {
  padding: 30px;
}

.auth-form__header {
  margin-bottom: 20px;
  display: block;
  height: 30px;
}

.auth-form__footer {
  padding-top: 20px;
}

.auth-form__title {
  color: rgba(var(--ac-dark-blue), .5);
  font-weight: 300;
  margin-bottom: 30px;
  font-size: 36px;
  line-height: 48px;
}

@media screen and (min-width: 600px) {

  .auth-panel {
    display: grid;
    grid-template-columns: 1fr 1fr;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
  }

  .auth-info--logo-wrapper {
    padding: 0 30px 0 0;
    margin-top: 165px;
  }


  .auth-info {
    padding: 30px;
    color: var(--ac-white);
    background-color: var(--ac-app-secondary-color);
    -webkit-border-top-left-radius: 3px;
    -webkit-border-bottom-left-radius: 3px;
    -moz-border-radius-topleft: 3px;
    -moz-border-radius-bottomleft: 3px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  .auth-form {
    padding: 30px;
    color: var(--ac-dark-blue);
  }

  .auth-info--logo {
    width: 100px;
    margin: 0 auto 50px;
  }

  .service-list {
    display: block;
    text-align:center;
    margin-bottom: 150px;
  }

  .service-list__title {
    font-weight: 300;
    font-size: 24px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  .auth-info--logos {
    display: block;
    position: absolute;
    width: calc(100% - 60px);
    bottom: 30px;
  }

  .auth-info--logos .logo-ac{
    float: right;
  }
  
  .auth-info--logos .logo-stag{
    float: left;
  }

}



/* @SERVICES PANEL */
.services__logo{
  width:100px;
  margin: 0 auto 50px;
}

@media only screen and (max-width: 600px) {
  .services__logo{
    margin-top: 30px;
  }
}

.services__header h1{
  text-align:center;
  font-weight: 300;
  margin-bottom: 50px;
  font-size: 32px;
}

.services__panel:after{
  content: "";
  clear: both;
  display: table;
}

.service{
  float:left;
  width:47.5%;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  text-align:center;
  padding: 50px 30px;
  min-height: 500px;
}

.service__logo-wrapper{
  height: 100px;
  margin-bottom: 50px;
}

.service__title{
  margin-bottom: 50px;
  font-weight: 300;
  font-size: 24px;
}

.service__description{
  margin-bottom: 50px;
  line-height: 26px;
  font-weight: 300;
}


@media only screen and (max-width: 800px) {
  .service{
    width: 90%;
    margin:0 5% 30px;
    min-height: auto;
  }
  .s-telematic{
    margin-right: 5%;
  }
}
