/* @TABLE */
.table-wrapper {
  width: 100%;
  overflow-x: auto;
  display: block;
  white-space: nowrap;
}

.table{
  width: 100%;
}

.table__header{
  border-bottom: 1px solid var(--ac-card-borders); 
}

.table__header th{
  padding: 10px 20px;;
  font-size: 12px;
  line-height: 16px;
  color: var(--ac-grey);
  text-align: left;
}

.table__body tr{
  border-bottom: 1px solid #deeff2;
  height: 50px;
}

.table__body tr.heavy td{
  font-weight: 700;
}

.table__body tr:last-child {
  border-color: var(--ac-card-borders); 
}

.table__body .error{
  border-bottom: 1px solid var(--ac-lightest-pink);
  height: 50px;
}

.table__body td{
  padding: 10px 20px;
  font-size: 14px;
  font-weight: normal;
  vertical-align: middle;
  line-height: 16px;
  position: relative;
}

.table__body td.simple{
  padding: 0;
  width: 30px;
}

.table__body td .dropdown{
  min-height: 30px;
  display:block;
}

.table__body td a {
  text-decoration: none;
}

.table__footer td{
  padding: 10px 5px 10px 0;
}

.table__body .multiple-lines{
  padding: 5px 20px;
  min-height: 40px;
}

.table__body--striped tr:nth-child(odd){
  background: var(--ac-lightest-blue);
}

.table__body--striped tr:hover{
  background: var(--ac-lightest-yellow);
}

.table__body--striped .error:nth-child(odd){
  background: var(--ac-lightest-pink);
}

.table__body--striped .error:nth-child(even){
  background: var(--ac-lighter-pink);
}

.table__body--striped .error:hover{
  background: var(--ac-hover-pink);
  border-color: var(--ac-hover-pink);
}

.table__body--striped tr:hover{
  background: var(--ac-lightest-yellow);
}

.table-idenitifier__icon{
  display:inline-block;
  position: absolute;
}

.table-idenitifier__title{
  display:block;
  margin-bottom: 5px;
}

.table-idenitifier__subtitle{
  display:block;
  color: var(--ac-grey);
  font-size: 12px;
  line-height: 12px;
}

.table__info-circle{
  background: var(--ac-dark-grey);
  color: var(--ac-white);
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  display: inline-block;
  position: absolute;
  right: 10px;
  font-size: 12px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.with-icon{
  padding-left: 60px;
}

.table__tinyspace{
  width: 10px;
}

.accordion{
  background: #f5f5f5;
  width: 100%;
  display:none;
}

.accordion--visible{
  display:table-row;
}

.accordion__body{
  padding: 20px 10px 40px;
  display: block;
}

.accordion__footer{
  padding: 0 10px;
  display: block;
}

.accordion__footer:after{
  content: '';
  display: block;
  clear: both;  
}

.accordion__footer .btn{
  width: 340px;
}

.accordion__trigger .material-icons{
  font-size: 40px;
}

.fuel-lvl__wrapper {
  border: 1px solid var(--ac-darkest-grey);
  display: block;
  width: 7px;
  height: 26px;
  background: var(--ac-white);
  position: relative;
}

.fuel-lvl {
  display: block;
  width: 5px;
  height: 24px;
  position: absolute;
}

.fuel-lvl--zero {
  background: var(--ac-white);
  height: 0px;
  bottom: 0;
}

.fuel-lvl--one {
  background: var(--ac-alert-danger);
  height: 6px;
  bottom: 0;
}

.fuel-lvl--two {
  background: var(--ac-alert-warning);
  height: 12px;
  bottom: 0;
}

.fuel-lvl--three {
  background: var(--ac-alert-success);
  height: 18px;
  bottom: 0;
}

.fuel-lvl--four {
  background: var(--ac-alert-success);
  height: 24px;
  bottom: 0;
}

.traffic-light {
  width: 10px;
  height: 10px;
  display: block;
  background-color: var(--ac-light-grey);
  border: 1px solid var(--ac-dark-grey);
  border-radius: 5px;
}

.traffic-light--inline {
  display: inline-block;
  position: relative;
  top: -4px;
  margin-right: 5px;
}

.traffic-light--orange {
  background-color: var(--ac-alert-warning);
}

.traffic-light--red {
  background-color: var(--ac-alert-danger);
}

.traffic-light--green {
  background-color: var(--ac-alert-success);
}

.table__highlight {
  display: inline-block;
  background: var(--ac-light-grey);
  border: 1px solid var(--ac-grey);
  color: var(--ac-dark-grey);
  padding: 2px 5px;
  border-radius: 2px;
}

.table__highlight--danger {
  background-color: var(--ac-alert-danger);
  border-color: var(--ac-alert-danger-darker);
  color: var(--ac-white);
}

.table__highlight--success {
  background-color: var(--ac-alert-success);
  border-color: var(--ac-alert-success-darker);
  color: var(--ac-white);
}

.table__highlight--warning {
  background-color: var(--ac-alert-warning);
  border-color: var(--ac-alert-warning-darker);
  color: var(--ac-white);
}

.table__highlight--info {
  background-color: var(--ac-alert-info);
  border-color: var(--ac-alert-info-darker);
  color: var(--ac-white);
}

.table__footer--button .btn
{
  position: relative;
  top: -5px;
  left: 20px;
}

.th--clickable {
  cursor: pointer;
  position: relative;
}

.th--clickable .app-icon {
  position: absolute;
  margin-top: -5px;
}

.table__header .th--ignore {
  padding: 0;
}

.th--ignore .ac-icon {
  padding: 0px;
  position: relative;
  top: 10px;
  left: 10px;
  background: var(--ac-lightest-grey);
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  line-height: 45px;
  border: 1px solid var(--ac-light-grey);
  margin-bottom: 20px;
}

.th--ignore .ac-icon:hover {
  background: var(--ac-white);
}



@media screen and (min-width: 800px) {
  .table{
      width: 100%;
      white-space: normal;
  }
}


