/* @FORM */
.form--padding {
  padding: 20px 10px;
}

label{
  color: var(--ac-dark-grey);
  font-size: 16px;
  display: block;
  margin-bottom: 5px;
  font-weight: 400;
}

.label--error {
  border: 1px solid var(--ac-alert-danger);
  background: var(--ac-hover-pink);
  border-radius: 15px;
  padding: 3px;
}

.currency-info{
  display: none;
}

.form-section {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid var(--ac-light-grey);
}

.form-section:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}

.form-group{
  margin-bottom: 30px;
}

.form-group:last-child {
  margin-bottom: 0;
}

.form-group.form-group--no-margin {
  margin-bottom: 0;
}

.form-group--section{
  margin-bottom: 50px;
}

.form-group--disabled, .form-group--disabled label{
  color: var(--ac-grey);
}

.form-group__title{
  font-size: 24px;
  color: var(--ac-grey);
  margin-bottom: 15px;
}

.form-group__radio .material-icons{
  position:relative;
  top: 5px;
}

.form-group__silent{
  color: var(--ac-grey);
}

.form-group__custom-settings {
  padding: 15px;
  background: var(--ac-lightest-grey);
  margin-bottom: 20px;
  border-radius: 5px;
}

.form-group__custom-settings .form-group:last-child {
  margin-bottom: 0;
}

.form-group__custom-settings .form-group__checkbox--large {
  margin-bottom: 15px;
}


.form-group__custom-settings .form-group__checkbox--large:last-child {
  margin-bottom: 0;
}

.form-group__checkbox,
.form-group__radio{
  display: block;
  margin-bottom: 10px;
}

.form-group__checkbox--large{
  margin-bottom: 30px;
}

.form-group__checkbox--auth .checkbox-section {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 30px;
}

.checkbox-section-title {
  font-weight: 900;
  margin-bottom: 10px;
}

.form input[type=checkbox],
.form input[type=radio]{
  border: 1px solid var(--ac-light-grey);
  width: 16px;
  height: 16px;
  position: relative;
  top: 2px;
  margin-right: 10px;
}

.location-search-input,
.form select,
.form input[type=date],
.form input[type=datetime-local],
.form input[type=text],
.form input[type=email],
.form input[type=number],
.form input[type=password]{
  font-size: 18px;
  font-family: 'Rubik', sans-serif;
  font-weight: 100;
  padding: 8px 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  min-height: 30px;
  line-height:30px;
  background: var(--ac-white);
  color: var(--ac-dashboard-black);
  border: 1px solid var(--ac-light-grey);
  display: block;
  font-weight: normal;
  transition: all 0.25s ease;
  width: 100%;
  box-sizing: border-box;
  -webkit-box-shadow: inset 0px 1px 1px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: inset 0px 1px 1px 0px rgba(0,0,0,0.1);
  box-shadow: inset 0px 1px 1px 0px rgba(0,0,0,0.1);
  -webkit-appearance: none;
  -moz-appearance: none;
}

.form select {
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}

input[type=range]{
  width: 100%;
}

.location-search-input:focus,
.form select:focus,
.form textarea:focus,
.form input[type=datetime]:focus,
.form input[type=text]:focus,
.form input[type=email]:focus,
.form input[type=number]:focus,
.form input[type=password]:focus{
  border: 1px solid var(--ac-dark-grey);
}

.location-search-input.input-error,
.form select.input-error,
.form textarea.input-error,
.form input[type=datetime].input-error,
.form input[type=text].input-error,
.form input[type=email].input-error,
.form input[type=number].input-error,
.form input[type=password].input-error{
  border: 1px solid var(--ac-red);
}

.form input[type=submit]{
  cursor: pointer;
}

.location-search-input:disabled,
.form select:disabled,
.form textarea:disabled,
.form input[type=datetime]:disabled,
.form input[type=text]:disabled,
.form input[type=email]:disabled,
.form input[type=number]:disabled,
.form input[type=password]:disabled{
  background: var(--ac-light-grey);
}

.form-group__explanation {
  display: block;
  font-size: 12px;
  line-height: 18px;
  color: var(--ac-grey);
}

.explanation--top-margin {
  margin-top: 5px;
}

.card__body > .form-group__explanation {
  padding-left: 10px;
}

.form textarea{
  -webkit-appearance: none;
  font-size: 18px;
  padding: 8px 30px;
  height: 120px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  line-height:30px;
  background: var(--ac-white);
  color: var(--ac-dark-grey);
  border: 1px solid var(--ac-light-grey);
  display: block;
  font-weight: normal;
  transition: all 0.25s ease;
  width: 100%;
  box-sizing: border-box;
  -webkit-box-shadow: inset 0px 1px 1px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: inset 0px 1px 1px 0px rgba(0,0,0,0.1);
  box-shadow: inset 0px 1px 1px 0px rgba(0,0,0,0.1);
}

.autocomplete-dropdown-container {
  max-width: 400px;
  font-size: 14px;
  font-weight: normal;
  margin-left: 20px;
  margin-top: 10px;
}

.password-visibility-icon {
  color: var(--ac-dark-grey);
  float: right;
  line-height: 24px;
}

.form input.input--inline {
  margin-bottom: 10px;
}

.password-reveal {
  cursor: pointer;
}


@media screen and (min-width: 992px) {

  label {
    max-width: 400px;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
  }

  .form-group{
    margin-bottom: 20px;
  }

  .form .location-search-input,
  .form textarea,
  .form select,
  .form input[type=date],
  .form input[type=datetime-local],
  .form input[type=text],
  .form input[type=email],
  .form input[type=number],
  .form input[type=password]{
      max-width: 400px;
      font-size: 14px;
      padding: 5px 18px;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      border-radius: 15px;
      line-height:20px;
      min-height: 20px;
  }

  
  .form .form-group.form-group--inline input[type=text],
  .form .form-group.form-group--inline input[type=email],
  .form .form-group.form-group--inline input[type=datetime],
  .form .form-group.form-group--inline input[type=number],
  .form .form-group.form-group--inline input[type=password]{
      display: inline-block;
      width: auto;
  }

  .form-group--inline-label{
      display: inline-block;
      width: 150px;
  }

  .currency-info-mobile{
      display: none;
  }

  .form-group.form-group--inline .currency-info{
      display: inline-block;
      width: auto;
      margin-left: 10px;
  }

  .form input.input--inline {
    display: inline-block;
    margin-right: 10px;
  }
}

.form .csv-input {
  border: 1px solid var(--ac-alert-danger);;
  font-size: 14px;
}


input[type="file"] {
  display: none;
}


.form input[type="text"].colored--green {
  background: var(--ac-alert-success);
}

.form input[type="text"].colored--yellow {
  background: var(--ac-alert-warning);
}

.form input[type="text"].colored--red {
  background: var(--ac-alert-danger);
}


