/* @CARD */
.card {
  position: relative;
  background: rgba(255,255,255,1);
}

.card--half {
  width: 100%;
  float: none;
}

.card__half--left {
  margin-right: 0;
}

.card__half--right {
  margin-left: 0;
}

.card__header {
  border-top: 1px solid var(--ac-card-borders);
  border-bottom: 1px solid var(--ac-card-borders);
  padding: 5px 10px;
}

.card__header-subtitle {
  display: none;
}

.card__header--warning,
.card__header--warning svg path {
  border-top: none;
  background: var(--ac-alert-warning);
  color: var(--ac-white);
  fill: var(--ac-white);
}

.card__header--danger,
.card__header--danger svg path {
  border-top: none;
  background: var(--ac-alert-danger);
  color: var(--ac-white);
  fill: var(--ac-white);
}

.card__header--success,
.card__header--success svg path {
  border-top: none;
  background: var(--ac-alert-success);
  color: var(--ac-white);
  fill: var(--ac-white);
}

.card__header-title {
  line-height: 32px;
  display: inline-block;
}

.card__header-subtitle {
  color: var(--ac-light-grey);
  margin-left: 0;
  font-size: 14px;
  position: relative;
  top: -2px;
}

.card__header--success .card__header-subtitle,
.card__header--danger .card__header-subtitle,
.card__header--warning .card__header-subtitle {
  color: var(--ac-white);
}

.card__header-icon {
  position: relative;
  top: 2px;
}

.card__header-actions-actions .btn {
  justify-items: end;
}

.card_header-search-container {
  display: block;
  width: auto;
  position: relative;
}

.card_header-search {
  font-family: 'Rubik', sans-serif;
  font-weight: 100;
  background: var(--ac-white);
  color: var(--ac-dark-grey);
  border: 1px solid var(--ac-card-borders);
  display: block;
  font-weight: normal;
  transition: all 0.25s ease;
  width: 100%;
  box-sizing: border-box;
  -webkit-box-shadow: inset 0px 1px 1px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: inset 0px 1px 1px 0px rgba(0,0,0,0.1);
  box-shadow: inset 0px 1px 1px 0px rgba(0,0,0,0.1);
  -webkit-appearance: none;
  -moz-appearance: none;
  font-size: 14px;
  padding: 5px 18px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  line-height:20px;
  min-height: 20px;
}

.card_header-search-button {
  position: absolute;
  top: 1px;
  right: -16px;
}

.card_header-close-button {
  position: absolute;
  top: 6px;
  right: 40px;
  text-align: center;
  line-height: 20px;
  font-size: 16px !important;
  display: block;
  width: 20px;
  height: 20px;
  cursor: pointer;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  background: var(--ac-light-grey);
}


.card_header-group-filter-container {
  display: block;
  width: auto;
  position: relative;
}


.card_header-group-filter {
  font-family: 'Rubik', sans-serif;
  font-weight: 100;
  background: var(--ac-white);
  color: var(--ac-dark-grey);
  border: 1px solid var(--ac-card-borders);
  display: block;
  font-weight: normal;
  transition: all 0.25s ease;
  width: 100%;
  box-sizing: border-box;
  -webkit-box-shadow: inset 0px 1px 1px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: inset 0px 1px 1px 0px rgba(0,0,0,0.1);
  box-shadow: inset 0px 1px 1px 0px rgba(0,0,0,0.1);
  -webkit-appearance: none;
  -moz-appearance: none;
  font-size: 14px;
  padding: 5px 18px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  line-height:20px;
  min-height: 20px;
}


.card__body {
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.card__body:after {
  content: '';
  display: block;
  clear: both;
}

.card__body--wrapper-small {
  padding: 10px 10px 30px;
}

.card__body--wrapper-medium {
  padding: 20px 20px 30px;
}

.card__body--wrapper-large {
  padding: 30px;
}

.card__body--wrapper-chart {
  padding: 20px 30px 10px 10px;
}

.card__footer:after {
  content: '';
  display: block;
  clear: both;
}

.card__header-select {
  -webkit-appearance: none;
  width: 100%;
  height: 40px;
  font-size: 18px;
  border: none;
  border-bottom: 1px solid var(--ac-light-grey);
  margin-top: 10px;
}

.card .section {
  margin-top: 20px;
}

@media screen and (min-width: 600px) {
  .card{
    margin-bottom: 30px;
    border-radius: 5px;
    border: 1px solid var(--ac-card-borders);
    -webkit-box-shadow: 0px 1px 10px 0px rgba(0,0,0,0.025);
    -moz-box-shadow: 0px 1px 10px 0px rgba(0,0,0,0.025);
    box-shadow: 0px 1px 10px 0px rgba(0,0,0,0.025);
    
  }
  .card__header{
    border-top: none;
    overflow: hidden;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  
  .card__header-subtitle {
    display: inline-block;
    margin-left: 20px;
  }

  .card__header-select {
    height: 30px;
    font-size: 14px;
    border: none;
    border-bottom: 1px solid var(--ac-light-grey);
    margin-top: 0;
  }

  .card__header-select-container {
    float: right;
  }

  .card__header-actions {

  }

  .card__header-actions .btn {
    width: auto;
    display: block;
    float: right;
  }

  .card_header-search-container {
    width: auto;
    display: block;
  }

  .card__header-action {
    display: inline-block;
    float: right;
    width: auto;
    max-width: 250px;
    margin-left: 5px;
  }

}

@media screen and (min-width: 992px) {
  .card--half {
      width: 48%;
      float: left;
  }
  
  .card__half--left {
      margin-right: 2%;
  }
  
  .card__half--right {
      margin-left: 2%;
  }

  .card_header-search-container {
    width: auto;
  }
}