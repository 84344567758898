/* @FOOTER PAGE */
.template__page {

  .footer {
    font-size: 12px;
    padding: 15px 30px;
    text-align: center;
    color: var(--ac-white);
    background: var(--ac-darkest-grey);
  }

  .footer__copyright {
    margin-bottom: 10px;
  }

  .footer a {
    text-decoration: none;
    color: var(--ac-white);
  }


  @media screen and (min-width: 600px) {

    .footer {
      background: var(--ac-lightest-grey);
      color: var(--ac-grey);
    }
    .footer a {
      color: var(--ac-grey);
    }
    
  }




  @media screen and (min-width: 992px) {

    .footer {
      padding: 15px 30px;
    }

    .footer:after {
      content: "";
      clear: both;
      display: table;
    }

    .footer__copyright {
      width: 30%;
      float: left;
      text-align: left;
      margin-bottom: 0;
    }

    .footer__address {
      width: 70%;
      float: left;
      text-align:right;
      margin-bottom: 0;
    }

  }

}


/* @FOOTER AUTH */
.template__auth {

  .footer {
    color: rgba(var(--ac-grey, .7));
    font-size: 12px;
    font-weight: 300;
    padding: 30px;
  }

  .footer__copyright {
    margin-bottom: 20px;
    text-align: center;
  }

  .footer__address {
    text-align: center;
    margin-bottom: 20px;
  }

  .footer a {
    display: inline-block;
  }

  .footer a, 
  .footer a:hover {
    color: rgba(var(--ac-grey, .7));
    text-decoration: none;
  }

  .footer__icon{
    font-size: 12px;
    margin-right: 5px;
  }

  .footer--phone{
    margin-left: 20px;
  }

  @media only screen and (min-width: 600px) {

    .footer {
      padding: 12px 0;
    }


    .footer__copyright {
      width: 45%;
      float: left;
      text-align: left;
      margin-bottom: 20px;
    }

    .footer__address {
      width: 55%;
      float: left;
      text-align:right;
      margin-bottom: 20px;
    }

  }

}