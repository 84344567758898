/* @TOOLBAR */
.toolbar__account-nav,
.toolbar__logo-title,
.toolbar__instructions
{
  display: none;
}

.toolbar{
  width: 100%;
  height: 60px;
  background: var(--ac-app-primary-color);
  position:fixed;
  top: 0;
  z-index: 4;
  -webkit-box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.15);
  -moz-box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.15);
  box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.15);
  display: grid;
  grid-template-columns: 60px auto auto;
}


.toolbar__logo-section{
  background-color: var(--ac-app-secondary-color);
  background-image: url(../img/bg-auth-panel-blue.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: var(--ac-white);
  width: 60px;
  height: 60px;
  line-height: 60px;
  box-sizing: border-box;
  padding: 10px;
}

.toolbar__logo-section a{
  text-decoration: none;
  color: var(--ac-white);
}


.toolbar__logo{
  width: 60px;
  height: 60px;
}

.toolbar__mobile-nav{
  position: absolute;
  top: 16px;
  right: 20px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  color: var(--ac-white);
}


.mobile-nav__hint {
  display: inline-block;
  font-weight: bold;
  position: absolute;
  top: 2px;
  right: 50px;
}


.toolbar__mobile-nav .ac-icon--menu{
  position: absolute;
}



@media screen and (min-width: 600px) {

  .toolbar{
    grid-template-columns: 60px 2fr auto auto;
    column-gap: 10px;
  }

  .toolbar__mobile-nav{
    display:none;
  }

  .toolbar__instructions {
    display: block;
    position: relative;
    top: 10px;
    right: 10px;
    background: var(--ac-white);
    color: var(--ac-dark-blue);
    height: 40px;
    padding: 0 25px 0 10px;
    border-radius: 25px;
    line-height: 40px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 900;
    text-decoration: none;
  }

  .ac-icon--help {
    position: relative;
    top: 7px;
    margin-right: 5px;
  }


  .toolbar__account-nav{
    display:inline;
    float: right;
    position: relative;
    top: 10px;
    right: 10px;
    background-color: var(--ac-white);
    border: 1px solid var(--ac-dark-blue);
    height: 40px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    transition: all 0.25s ease;
    cursor: pointer;
  }

  .toolbar__account-nav{
    display: block;
    position: relative;
    top: 10px;
    right: 10px;
    background-color: var(--ac-white);
    border: 1px solid var(--ac-dark-blue);
    height: 40px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    transition: all 0.25s ease;
    cursor: pointer;
  }

  .toolbar__account-nav:hover{
    background: var(--ac-dark-grey);
    border-color: var(--ac-white);
  }

  .toolbar__account-nav:hover .toolbar__account-nav-link {
    color: var(--ac-white);
  }

  .toolbar__account-nav:hover .toolbar__account-nav--account-icon svg path,
  .toolbar__account-nav:hover .toolbar__account-nav--dropdown-icon svg #arrow-path {
    fill: var(--ac-white);
  }


  .toolbar__logo-client {
    width: 30px;
    height: 30px;
    display: inline-block;
    background: var(--ac-white) url(../img/logo-client.png) no-repeat center;
    background-size: cover;
    position: absolute;
    left: 5px;
    top: 3px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
  }

  .toolbar__account-nav-link{
    width: auto;
    height: auto;
    display: block;
    padding: 5px 40px;
    height: 40px;
    text-decoration: none;
    color: var(--ac-dark-grey);
  }

  .toolbar__account-nav--username{
    display: block;
    font-size: 16px;
    font-weight: normal;
    position: relative;
    top: -4px;
    min-height: 15px;
  }

  .toolbar__account-nav--email{
    display:block;
    font-size: 12px;
    position: relative;
    top: -13px;
  }


  .toolbar__account-nav--account-icon{
    position: absolute;
    top: 4px;
    left: 3px;
  }

  .toolbar__account-nav--dropdown-icon{
    position: absolute;
    top: 7px;
    right: 10px;
  }

}



@media screen and (min-width: 992px) {

  .toolbar__logo{
    display: none;
  }

  .toolbar__logo-title{
    display: block;
    margin-top: -10px;
    margin-left: 20px;
  }

  .toolbar__logo-section{
    width: 250px;
  }

}
