/* @GENERAL RULES */
html, body, #root, .page {
  height: 100%;
}

body{
  background-color: var(--ac-dark-grey);
  color: var(--ac-dark-grey);
  font-family: 'Rubik', sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

@media screen and (min-width: 600px) {
  body{
    font-weight: 300;
  }
}

body *{
  box-sizing: border-box;
  /* -webkit-appearance: none; */
  border-radius: 0;
}

body *:focus {
  outline: 0;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: var(--ac-grey);
}
::-moz-placeholder { /* Firefox 19+ */
  color: var(--ac-grey);
}
:-ms-input-placeholder { /* IE 10+ */
  color: var(--ac-grey);
}
:-moz-placeholder { /* Firefox 18- */
  color: var(--ac-grey);
}

a, a:active, a:focus {
  color: var(--ac-blue);
}

a:hover {
  color: var(--ac-dark-grey);
}

em{
  font-weight: 500;
}

sup { 
vertical-align: super;
font-size: smaller;
}

small {
  font-size: 12px;
  color: var(--ac-grey);
}

strong {
  font-weight: 500;
}

img {
  display: block;
}

h1{
  font-size: 32px;
  line-height: 48px;
  font-weight: normal;
}

h2{
  font-size: 24px;
  line-height: 38px;
  font-weight: normal;
}

h3{
  font-size: 20px;
  line-height: 28px;
  font-weight: normal;
}

.csection.csection__small {
  margin-bottom: 10px;
}

.csection.csection__medium {
  margin-bottom: 20px;
}

.csection.csection__large {
  margin-bottom: 30px;
}

.csection-title {
  margin-bottom: 10px
}

.apexcharts-canvas {
  z-index: 1;
}

.phrase-wrapper {
  position: relative;
}

.phrase-number {
  background: red;
  color: var(--ac-white);
  font-size: 8px;
  height: 12px;
  padding: 2px;
  line-height: 10px;
  text-decoration: none;
  position: absolute;
  top: -8px;
}


.small {
  font-size: 12px;
}

.template__page .main-content p.small--estimates {
  margin-top: 5px;
  margin-bottom: 0;
  margin-left: 10px;
}

.text-error {
  color: var(--ac-alert-danger);
}


/* SPECIFIC PAGES AROUND CHARTS */
.fuel-level-chart {
  margin-bottom: 20px;
}