/* @TABNAV */
.tabnav-tab {
  display: block;
  padding: 10px 20px 7px;
  border-bottom: 1px solid var(--ac-light-grey);
  font-size: 14px;
  color: var(--ac-grey);
  text-decoration: none;
  background: var(--ac-white);
  font-weight: 400;
}

.tabnav-tab--active {
  color: var(--ac-white);
  background: var(--ac-blue);
}

.tabnav-tab__arrow {
  float: right;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 45px;
  position: relative;
  top: -3px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: var(--ac-grey);
}

.tab-nav__buffer-info {
  padding: 10px;
  background: var(--ac-alert-warning);
}

.tabnav-wrapper-button {
  display: block;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #D4D4D4;
  height: 50px;
}


@media screen and (min-width: 600px) {
  .tabnav-wrapper {
    border-bottom: 1px solid var(--ac-card-borders);
  }

  .tabnav-wrapper-button {
    position: relative;
    top: 7px;
    display: inline;
    width: auto;
    padding: 0;
    height: auto;
    border: none;
  }  

  .tabnav-tab {
    display: inline-block;
    padding: 10px 18px;
    border-bottom: 3px solid var(--ac-white);
    font-size: 14px;
    color: var(--ac-grey);
    text-decoration: none;
  }

  .tabnav-tab--active {
    border-color: var(--ac-blue);
    background: var(--ac-lightest-grey);
    color: var(--ac-dark-grey);
  }

  .tabnav-tab__arrow {
    display: none;
  }
}