/* @FILTER */
.filter {
  font-size: 12px;
  color: var(--ac-grey);
  padding: 10px;
  background: var(--ac-dark-grey);
}

.filter .react-datepicker-wrapper {
  width: 100%;
}

.form .filter input[type=text],
form .filter__select {
  width: 100%;
  margin-bottom: 10px;
}

.filter__filter-text,
.filter__item-num {
  color: var(--ac-white);
  display: block;
}

.filter__next,
.filter__prev{
  display: inline-block;
  background: var(--ac-light-grey);
  border: 1px solid var(--ac-light-grey);
  width: 40px;
  height: 40px;
  line-height: 55px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  text-align: center;
  margin-right: 5px;
}

a.filter__next,
a.filter__prev,
.filter__next--active,
.filter__prev--active{
  background: var(--ac-blue);
  border: 1px solid var(--ac-blue-borders);
  color: var(--ac-white);
  cursor: pointer;
}

.vehicle-savings-filter {
  background-color: var(--ac-white);
  box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.15);
  border-radius: 30px;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 20px;
  padding: 10px;
  font-size: 17px;
  width: 180px;
  border: 2px solid var(--ac-dark-grey);
  padding-left: 40px;
  position: relative;
}

.vehicle-savings-filter.vehicle-savings-filter--extended {
  min-width: 100%;
}

.filter_filter-icon {
  position: absolute;
  left: 20px;
}

.vehicle-savings-filter .react-datepicker-wrapper {
  margin: 0 5px 0 10px;
}

.vehicle-savings-filter .filter-select__group {
  margin-left: 10px;
}

.vehicle-savings-filter .filter-select__group select {
  border: none;
  font-size: 18px;
  font-weight: 500;
  font-family: "Rubik", sans-serif;
  margin-bottom: -1px;
  width: 150px;
  display: inline-block;
}

.vehicle-savings-filter span {
  display: inline-block;
}

.vehicle-savings-filter .filter_filter-select input {
  border: none;
  font-size: 17px;
  font-weight: 900;
  background: none;
  width: 80px;
  cursor: pointer;
  width: 100px;
  display: inline-block;
}

@media screen and (min-width: 1000px) {

  .vehicle-savings-filter.vehicle-savings-filter--extended {
    min-width: 480px;
  }

  .filter {
    padding-top: 0;
  }

  .filter__filter-group {
    width: 20%;
    margin-right: 2%;
    display: inline-block;
  }

  .filter-group--last {
    width: 12%;
    margin-right: 0;
  }

  .filter__filter-text {
    padding-top: 10px;
  }

  .filter__filter-text,
  .filter__item-num {
    color: var(--ac-white);
    display: block;
    line-height: 12px;
    margin: 0 0 5px;
  }

  .form .filter input[type=text],
  .form .filter select {
    margin-bottom: 0;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
  }

  .filter__next,
  .filter__prev {
    width: 30px;
    height: 30px;
    line-height: 45px;
  }

  .filter__nav {
    position: relative;
    bottom: -7px;
  }

  .filter__item-num {
    position: relative;
    bottom: -7px;
  }

}